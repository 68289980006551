import { graphql } from "gatsby";
import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Obfuscate from "react-obfuscate";

import Card from "@df/multichannel-app/packages/shared-web/components/Card";
import Chips from "@df/multichannel-app/packages/shared-web/components/Chips";
import { Consumer as LayoutContextConsumer } from "@df/multichannel-app/packages/shared-web/contexts/Layout";

import SEO from "../components/SEO";
import SiteLayout from "../containers/SiteLayout";

import {
  cardGridContainerStyles,
  gridContainerStyles,
} from "@df/multichannel-app/packages/shared-web/styles/grid";
import { MdxNode } from "@df/multichannel-app/packages/shared/interfaces/markdown";

export interface BlogIndexProps {
  data: {
    allMdx: {
      edges: [
        {
          node: MdxNode;
        }
      ];
    };
  };

  location: {
    pathname: string;
  };
}

const Item = ({ heading, body }: { heading: string; body: JSX.Element }) => (
  <AccordionItem>
    <AccordionItemHeading>
      <AccordionItemButton
        style={{
          cursor: "pointer",
          outline: "none",
          padding: "5px 0",
        }}
      >
        <AccordionItemState>
          {(state) => (
            <h2 style={{ fontSize: "1rem", margin: 0, lineHeight: 1.4 }}>
              <span
                style={{
                  display: "inline-block",
                  content: "",
                  height: "10px",
                  width: "10px",
                  marginRight: "10px",
                  borderBottom: "2px solid currentColor",
                  borderRight: "2px solid currentColor",
                  transform: `rotate(${
                    state.expanded === true ? "45deg" : "-45deg"
                  })`,
                }}
              />
              {heading}
            </h2>
          )}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel
      style={{
        padding: "5px 0",
      }}
    >
      {body}
    </AccordionItemPanel>
  </AccordionItem>
);

const itemPstyle: React.CSSProperties = {
  marginBottom: "5px",
};

class BlogIndex extends React.Component<BlogIndexProps> {
  render() {
    const { data } = this.props;
    const posts = data.allMdx.edges;

    return (
      <SiteLayout location={this.props.location}>
        <SEO homepage={true} path="/" />

        <LayoutContextConsumer>
          {({ breakpoint }) => (
            <>
              <div
                style={{
                  ...gridContainerStyles,
                }}
              >
                <p>
                  Web, mobile, desktop, and cloud; application, software
                  development and technical solutions; for small business and
                  enterprises.
                </p>

                <Accordion
                  allowZeroExpanded={true}
                  allowMultipleExpanded={true}
                  style={{ marginBottom: "1.5rem", display: "inline-block" }}
                >
                  <Item
                    heading="Web app, website and mobile-web development"
                    body={
                      <p style={itemPstyle}>
                        React, JavaScript, CMS (WordPress, Drupal)
                      </p>
                    }
                  />
                  <Item
                    heading="Mobile app development"
                    body={<p style={itemPstyle}>React Native</p>}
                  />
                  <Item
                    heading="Cloud app development, API development"
                    body={<p style={itemPstyle}>AWS, Azure, Node.js, C, PHP</p>}
                  />
                  <Item
                    heading="UX &amp; UI"
                    body={
                      <ul style={itemPstyle}>
                        <li>UX strategy and design</li>
                        <li>UI digital design</li>
                      </ul>
                    }
                  />
                </Accordion>

                <p>
                  Contact us on:{" "}
                  <Obfuscate
                    email="projects@fallowspicking.uk"
                    headers={{
                      subject: "Contact us - fallowspicking.uk",
                    }}
                  />
                </p>

                {posts.length > 0 && (
                  <section
                    style={{
                      ...gridContainerStyles,
                      marginTop: "3rem",
                      marginBottom: "3rem",
                    }}
                  >
                    <h2 id="blog">Blog</h2>

                    <div style={{ ...cardGridContainerStyles(breakpoint) }}>
                      {posts.map(({ node }: { node: MdxNode }, i) => {
                        const path = node.frontmatter.path || node.fields.path;
                        const title = node.frontmatter.title;
                        const description =
                          (node.frontmatter.seo &&
                            node.frontmatter.seo.description) ||
                          node.excerpt;

                        return (
                          <Card
                            key={path}
                            width={breakpoint === "sm" ? 100 : 50}
                            to={path}
                            title={title}
                          >
                            <h3
                              style={{
                                marginBottom: "0.5rem",
                              }}
                            >
                              {title}
                            </h3>

                            <p
                              style={{
                                fontSize: "0.9em",
                                marginBottom: "1rem",
                              }}
                            >
                              {description}
                            </p>

                            {node.frontmatter.taxonomy && (
                              <Chips
                                chips={node.frontmatter.taxonomy.map(
                                  (t: string) => `#${t}`
                                )}
                              />
                            )}
                          </Card>
                        );
                      })}
                    </div>
                  </section>
                )}
              </div>
            </>
          )}
        </LayoutContextConsumer>
      </SiteLayout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___created], order: DESC }
      filter: { fields: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            path
          }
          frontmatter {
            title
            taxonomy
            seo {
              description
            }
          }
        }
      }
    }
  }
`;
